<template>
  <div class="app_con">
    <div class="p-20">
      <div class="flex m-b-20">
        <el-button type="warning" @click="exportExcel" icon="el-icon-download"
          >下载模板</el-button
        >

        <el-button
          slot="trigger"
          type="primary"
          @click="importExcel"
          icon="el-icon-upload2"
          >导入数据</el-button
        >
        <input
          class="inputFile"
          ref="inputFile"
          type="file"
          @change="changeFile"
        />
      </div>
      <el-table :data="tagList" border style="width: 100%">
        <template v-for="v in props">
          <el-table-column
            :key="v.id"
            :prop="v.prop"
            :label="v.label"
            :width="v.width ? v.width : ''"
          >
            <template slot-scope="scope">
              <span :class="setColorClass(scope.row.tagName)" v-if="v.type && v.type == 'tag'"> {{scope.row.tagName}}</span>
              <span :class="setColorClass(scope.row.validity)" v-else-if="v.type == 'validity'"> {{scope.row.validity}}</span>
              <span v-else>{{scope.row[v.prop]}}</span>
            </template>


          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/tagInfo",
      tagList: [],
      props: [
        {
          label: "ID",
          prop: "id",
          align: "center",
        },
        {
          label: "姓名",
          prop: "name",
          align: "center",
        },
        {
          label: "身份证号",
          prop: "idCard",
          align: "center",
          width: 180,
        },
        {
          label: "标签名称",
          prop: "tagName",
          align: "center",
          type: "tag",
        },
        {
          label: "有效性",
          prop: "validity",
          align: "center",
          type: "validity",
        },
        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 获取列表数据
    async getList() {
      let { remote, apiName } = this;
      let result = await remote.list({ apiName });
      result.data.data.forEach((x) => {
        if (x.tagName == "") {
          x.tagName = "暂无数据";
        }
        if (x.validity == "") {
          x.validity = "暂无数据";
        }
      });
      this.tagList = result.data.data;
    },
    setColorClass(data) {
      let str = "";
      switch (data) {
        case "有效":
          str = `font-green`;
          break;
        case "无效":
          str = `font-red`;
          break;
        case "暂无数据":
          str = `font-blue`;
          break;
        default:
          str = `font-blue`;
      }
      return str;
    },
    // 下载模板
    async exportExcel() {
      await api.common.download({
        apiName: "/tagInfo/exportModel",
      });
    },
    // 导入数据
    async importExcel() {
      this.$refs.inputFile.click();
    },
    // 选择文件回调
    async changeFile(event) {
      let fileList = event.target.files;
      if (fileList.length > 0) {
        let file = fileList[0];
        let formData = new FormData();
        formData.append("file", file); //你的post接口，formData发送
        let result = await api.common.upload({
          apiName: "/tagInfo/importModel",
          file: formData,
        });
        console.log(result);
        this.$message({ type: "success", message: result.message });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.inputFile {
  display: none;
}
.fullWidth-label {
  padding: 0 10px;
  width: 170px;
}
.avatar {
  width: 170px;
  height: 250px;
  display: block;
}
.font-green {
  color: #67c23a;
}
.font-red {
  color: #f56c6c;
}
.font-blue {
  color: #409eff;
}
</style>
